//
// Modules
//

	// Sticky Kit

	var stickyElements = {
		init: function() {

			$('.js-sticky-element')
				.stick_in_parent(
					{
						bottoming: true,
						recalc_every: 5
					}
				);

		}
	}

	// Show / Hide

	var showAndHide = {
		init: function() {

			$('.js-show-hide').click(

				function() {

					var target = $(this).data('target'),
						action = $(this).data('action');

						$('.js-show-hide').removeClass('is-active');

						$(this).addClass('is-active');

					if (action == "show") {

						$('[data-show-id=' + target + ']').removeClass('is-hidden');

					} else {

						$('[data-show-id=' + target + ']').addClass('is-hidden');

					}

				}

			);

		}

	}

	// Show / Hide

	var valueChange = {
		init: function() {

			$('.js-value-change').change(

				function() {

					var input = $(this).data('input-id'),
						value = $(this).val(),
						target = $(this).data('target');

					$('[data-input-id="' + target + '"]').val(value);

				}

			);

			$('.js-input-change').keyup(

				function() {

					var input = $(this).data('input-id'),
						value = $(this).val(),
						target = $(this).data('target');

					$('[data-input-id="' + target + '"]').val(value);

				}

			);


		}

	}

	// --------------------------------------------------------

	// Form Switch

	var formSwitch = {
		init: function(){

			$('.form-switch > div').click(

				function() {

					var form = $(this).data('form-id');

					$('.form-switch > div').removeClass('is-active');

					$(this).addClass('is-active');

					$('.forms').removeClass('is-active');
					$('[data-form=' + form + ']').addClass('is-active');

				}

			)

		}
	}

	// Show / Hide

	var showHide = {
		selectors: {
			show: '.js-show',
			showObj: $('.js-show')
		},
		init: function() {

			this.selectors.showObj
				.click(
					function(event){

						var target = $(this).data('show-target'),
							group = $('[data-show-id="' + target + '"]').data('show-group');

						$('[data-show-group="' + group + '"]')
							.addClass('is-hidden');

						$('[data-show-id="' + target + '"]')
							.removeClass('is-hidden');

					}
				);

		}
	}

	// Validation

	var validation = {
		init: function() {

			$('.js-form-validate')
				.shapeValidate();

		}
	}

	// Copy Groups

	var copyGroups = {
		copyData: function(object) {

			var groupContent = '';

			var group = $(object).data('copy-group');

			$('[data-copy-group="' + group + '"]')
				.each(
					function(){

						if ($(this).is('select')) {
							var groupValue = $('option:selected', this).text();
						}
						else {
							var groupValue = $(this).val();
						}

						if (groupValue) {
							if ($(this).attr('id') == 'first-name') {
								groupContent += groupValue + ' ';
							}
							else {
								groupContent += groupValue + '<br>';
							}
						}

					}
				);

			$('[data-copy-group-output="' + group + '"]').html(groupContent);

		},
		copyCollectionData: function(object) {

			$(object)
				.find('[data-copy-group]')
				.each(
					function(){

						var group = $(this).data('copy-group');
							groupContent = $(this).html();

						$('[data-copy-group-output="' + group + '"]').html(groupContent);

					}
				);

		},
		init: function() {

			$('[data-copy-group-collection]')
				.click(
					function(event){

						copyGroups.copyCollectionData(this);

						event.preventDefault();

					}
				);

			$('[data-copy-group]')
				.keyup(
					function(){
						copyGroups.copyData(this);
					}
				);

			$('[data-copy-group]')
				.change(
					function(){
						copyGroups.copyData(this);
					}
				);

		}
	}

	// Option Rows

	var optionRows = {
		init: function(){

			$('.js-field-option-row')
				.click(
					function(){

						var optionRowSiblings = $(this).siblings(),
							optionRowInput = $(this).find('input'),
							optionRowSwitch = $(this).attr('data-field-option-switch');

						optionRowSiblings.removeClass('is-checked');
						optionRowSiblings.find('input').removeAttr('checked');

						$(this).addClass('is-checked');
						optionRowInput.prop('checked', 'checked');

						if (typeof optionRowSwitch !== typeof undefined && optionRowSwitch !== false) {

							var optionRowTarget = $(this).attr('data-field-option-switch-target');

							if (optionRowSwitch === 'show') {
								$('[data-field-option-switch-group="' + optionRowTarget + '"]')
									.removeClass('is-hidden')
							}
							else {
								$('[data-field-option-switch-group="' + optionRowTarget + '"]')
									.addClass('is-hidden');
							}

						}

					}
				);

		}
	}

	// Basket Totals

	var deliveryUpdate = {
		init: function(){

			$('.js-delivery-update')
				.click(
					function(event){

						var deliveryValue = $(this).data('delivery-update-value');
						var deliveryTotalValue = $(this).data('delivery-update-value-total');

						$('[data-delivery-update="delivery"]').text(deliveryValue);
						$('[data-delivery-update="total"]').text(deliveryTotalValue);

						event.preventDefault();

					}
				);

		}
	};

	// Form Submit

	var formSubmit = {
		init: function(){

			$('.js-form-submit')
				.click(
					function(){

						var formIdObject = $('#form-commerce');


	               if (( typeof(formIdObject[0].checkValidity) == "function" ) && !formIdObject[0].checkValidity()) {

							formIdObject[0].reportValidity();
                     return;

						}

						else {

							var formId = $(this).data('form-submit')
							$('.js-loader').addClass('is-visible');
							$('#' + formId).submit();

						}

					}
				);

		}
	}

	// Form Submit Dynamic

	var formSubmitDynamic = {
		init: function(){

			$('.js-form-submit-dynamic')
				.click(
					function(event){

						$('.js-loader').addClass('is-visible');
						$(this).closest('form').submit();

						event.preventDefault();

					}
				);

		}
	}

	// Input Format

	var inputFormat = {
		init: function(){

			$('.js-input-format-credit-card')
				.formatter(
					{
						'pattern': '{{9999}} {{9999}} {{9999}} {{9999}} {{9999}}',
						'persistent': true
					}
				);

		}
	}


	// Switch

	var checkboxSwitch = {
        change: function(object) {

            var group = object.data('checkbox-switch');

            // Make fields available
            $('[data-checkbox-switch-group="' + group + '"]')
                .find('input, select')
                .each(function(){
                    $(this).prop('disabled', false);
                });

            // Set status
            $('[data-checkbox-switch-group="' + group + '"].is-hidden')
                .removeClass('is-hidden')
                .siblings('[data-checkbox-switch-group="' + group + '"]')
                .addClass('is-hidden');

            // Disable fields that are hidden
            $('[data-checkbox-switch-group="' + group + '"].is-hidden')
                .find('input, select')
                .each(function(){
                    $(this).prop('disabled', true);
                });

            event.preventDefault();

        },
        init: function() {

            $('.js-checkbox-switch')
                .change(
                    function(event){
                        checkboxSwitch.change($(this));
                    }
                );

            if($('.js-checkbox-switch').is(':checked')) {
                var group = $('.js-checkbox-switch').data('checkbox-switch');
                $('[data-checkbox-switch-group="' + group + '"].is-hidden')
                    .find('input, select')
                    .each(function(){
                        $(this).prop('disabled', true);
                    });
            }

        }
    }

// ----------------------------------------------------------------------------------------------------------------

//
// Initalise
//

	// Ready

	$(document)
		.ready(
			function(){
				stickyElements.init();
				showHide.init();
				checkboxSwitch.init();
				validation.init();
				copyGroups.init();
				optionRows.init();
				deliveryUpdate.init();
				formSubmit.init();
				formSubmitDynamic.init();
				inputFormat.init();
				showAndHide.init();
				valueChange.init();
				formSwitch.init();
			}
		);

	// Resize

	$(window)
		.resize(
			function(){
			}
		);

	// Scroll

	$(window)
		.scroll(
			function(){
			}
		);
